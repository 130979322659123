export function centsToDollars(value: number): number;
export function centsToDollars(value: string): number | string;
export function centsToDollars<T>(value: T): T;

/**
 * Converts a value from cents to dollars, rounding to the nearest dollar using money rounding rules
 */
export function centsToDollars(value: unknown): unknown {
  const numberValue = parseInt(value as string, 10);
  if (!value || Number.isNaN(numberValue)) return value;
  return Math.round(Math.trunc(numberValue / 10) / 10);
}

export function dollarsToCents(value: number): number;
export function dollarsToCents(value: string): number | string;
export function dollarsToCents<T>(value: T): T;

/**
 * Converts a value from dollars to cents
 */
export function dollarsToCents(value: unknown): unknown {
  const numberValue = parseInt(value as string, 10);
  if (!value || Number.isNaN(numberValue)) return value;
  return numberValue * 100;
}

export function centsToDollarsWithCommas(value: number): string | number;
export function centsToDollarsWithCommas(value: string): string;
export function centsToDollarsWithCommas<T>(value: T): T;

/**
 * Converts a value from cents to dollars, rounding to the nearest dollar and formatting with commas
 */
export function centsToDollarsWithCommas(value: unknown): unknown {
  const numberValue = parseInt(value as string, 10);
  if (!value || Number.isNaN(numberValue)) return value;
  return Intl.NumberFormat('en-US').format(centsToDollars(numberValue));
}
